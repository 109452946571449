var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { initTranslation } from "../modules/i18n.js";
import initPage from "../modules/page";
import initGeoIPModal from "../modules/geoip-modal";
import { initCarousels } from "../modules/product-collection";
import { createProductImpressionObserver } from "../modules/analytics.js";
import { sendFacebookPixelEvent } from "../modules/analytics/facebook-pixel.js";
import { initAccordion } from "../modules/ui.js";
import { getKeyboardFocusableElements, disableBodyScroll, enableBodyScroll, } from "../modules/utilities.js";
import { initCategoryCarousel } from "../modules/categories";
import "../../scss/brand.scss";
import { FruugoAnalyticsSearchCompleteEvent } from "../modules/analytics/fruugo-analytics/FruugoAnalyticsSearchCompleteEvent";
import { select, selectAll } from "@fruugo/utilities";
function initBrandPage() {
    return __awaiter(this, void 0, void 0, function () {
        var observer, sidebar, backdrop_1, keyboardElements, firstFocusable_1, openSidebar, closeSidebar, mobileFilters;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, initTranslation()];
                case 1:
                    _b.sent();
                    initPage();
                    initGeoIPModal();
                    initCarousels();
                    initPageSpecificAnalytics();
                    initAccordion();
                    initCategoryCarousel();
                    saveInteractionId();
                    observer = createProductImpressionObserver();
                    document
                        .querySelectorAll(".product-item")
                        .forEach(function (item) { return observer.observe(item); });
                    // Utility Menus
                    document.querySelectorAll("#sort-by, #page-size").forEach(function (select) {
                        return select.addEventListener("change", function (e) {
                            var target = e.target;
                            window.location.href = target === null || target === void 0 ? void 0 : target.value;
                        });
                    });
                    sidebar = select(".sidebar");
                    if (sidebar) {
                        backdrop_1 = document.createElement("div");
                        keyboardElements = getKeyboardFocusableElements(sidebar);
                        firstFocusable_1 = keyboardElements[0];
                        backdrop_1.classList.add("sidebar-backdrop");
                        openSidebar = function () {
                            disableBodyScroll();
                            document.body.classList.add("sidebar-open");
                            document.body.appendChild(backdrop_1);
                            // put focus just after close button, so first tab selects first useable item instead
                            if (firstFocusable_1) {
                                firstFocusable_1.focus();
                                firstFocusable_1.blur();
                            }
                        };
                        closeSidebar = function () {
                            var _a;
                            enableBodyScroll();
                            document.body.classList.remove("sidebar-open");
                            (_a = backdrop_1.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(backdrop_1);
                        };
                        mobileFilters = select(".mobile-filters");
                        mobileFilters && mobileFilters.addEventListener("click", openSidebar);
                        (_a = sidebar
                            .querySelector(".btn-close")) === null || _a === void 0 ? void 0 : _a.addEventListener("click", closeSidebar);
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function saveInteractionId() {
    if (window.searchInformation && window.searchInformation.interactionId) {
        window.sessionStorage.setItem("interactionId", window.searchInformation.interactionId);
    }
}
function initPageSpecificAnalytics() {
    if (window.pageType === "search") {
        if (!window.searchInformation.searchQuery)
            return;
        sendFacebookPixelEvent("Search", {
            content_ids: __spreadArray([], Array.from(selectAll(".product-item")), true).map(function (p) { return p.dataset.productid; }),
            search_string: window.searchInformation.searchQuery,
        });
        var event_1 = new FruugoAnalyticsSearchCompleteEvent(window.searchInformation);
        event_1.send();
    }
}
initBrandPage();
